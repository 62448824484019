.home-page {
  font-family: 'Inter', sans-serif;
  color: #333;
}

header {
  background-color: #4caf50;
  color: white;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 20px; /* Add left margin to the logo */
}

.rotating-2 {
  display: inline-block;
  animation: rotate 10s linear infinite;
  margin-right: 0.15em; /* Increased space after the "2" */
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

nav {
  margin-right: 20px; /* Add right margin to the navigation */
}

nav ul {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

nav li {
  font-weight: bold;
}

nav a {
  color: white;
  text-decoration: none;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.hero-content {
  text-align: center;
  padding: 0;
  background-image: url('./greenh2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.hero-content h1 {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  max-width: 800px;
}

.cta-button {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 1rem;
}

#features {
  padding: 4rem 2rem;
}

.features-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 5%;
}

.features-section h2 {
  margin-bottom: 2rem;
}

.feature-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 250px;
  max-width: 350px;
}

.feature-card i {
  font-size: 2rem;
  color: #4caf50;
  margin-bottom: 1rem;
}

#contact {
  background-color: #e9ecef;
  padding: 4rem 2rem;
}

#contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#contact-form input,
#contact-form textarea {
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

footer {
  background-color: #343a40;
  color: white;
  padding: 2rem;
  text-align: center;
}

.social-icons {
  margin-top: 1rem;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  margin: 0 0.5rem;
}